<template>
  <div class="container mt-5 pl-5">
    <h2 class="mx-2 mb-3">
      {{ $t('settings.payment_method.title') }}
    </h2>
    <template v-if="isShownPaymentConfigNotice">
      <div class="PaymentConfigNotice">
        <p class="PaymentConfigNotice__title emobg-font-size-large">
          {{ $t('business_profile.payment_method.employee') }}
        </p>
        <p class="PaymentConfigNotice__text">
          {{ $t('business_profile.payment_method.description_1') }}
        </p>
        <p class="PaymentConfigNotice__center-v d-flex flex-column align-items-center justify-content-center">
          <a
            class="PaymentConfigNotice__link emobg-font-size-x-large"
            :href="`+tel${csOperatorPhone}`"
          >
            {{ csOperatorPhone }}
          </a>
        </p>
        <p
          class="PaymentConfigNotice__text"
          v-html="$t('business_profile.payment_method.customer_care', {
            workingHours: csOperatorWorkingHours,
          })"
        />
      </div>
    </template>

    <template v-else>
      <PaymentMethodsHub
        :action="ACTIONS.list"
        :provider="adyenProviderVersion"
        @on:add-payment-method-authorised="response => onResponse(response, RESPONSE_STATUS.authorised)"
        @on:add-payment-method-refused="response => onResponse(response, RESPONSE_STATUS.refused)"
        @on:add-payment-method-error="response => onResponse(response, RESPONSE_STATUS.error)"
        @on:error-message="onPaymentMethodError"
        @on:success-message="onPaymentMethodSuccess"
      />

      <PaymentMethodsHubListener
        :modal-text="successFeedbackText"
        :stop-event-propagation="true"
      />
    </template>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';

import { ACTIONS, DEFAULT_PAYMENT_PROVIDER, RESPONSE_STATUS } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import PaymentMethodsHub from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHub';
import PaymentMethodsHubListener from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHubListener.vue';
import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';

import { useSegment } from '@/composable/Segment/segment';
import { SEGMENT_EVENTS } from '@/vue/constants';
import { emptyPaymentMethods } from '@/utils/publicImages';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

export default {
  name: 'PaymentMethodsTab',

  components: {
    PaymentMethodsHub,
    PaymentMethodsHubListener,
  },

  inject: ['usingCompanyAdmin'],

  setup(_props, context) {
    const {
      currentCSOperator, isAdminInCompanyPays, onAddPaymentAction, userIsAdmin, storeData,
    } = commonImplementationHelper(context);
    const { notifyError, notifySuccess } = useNotifications();
    const currentProfile = storeData.profile;
    const hasPaymentMethod = !!currentProfile.paymentMethod;
    const { trackSegment } = useSegment();

    return {
      currentCSOperator,
      isAdminInCompanyPays,
      onAddPaymentAction,
      hasPaymentMethod,
      userIsAdmin,
      notifyError,
      notifySuccess,
      trackSegment,
    };
  },

  data() {
    return {
      checkUsingCompanyAdmin: this.usingCompanyAdmin,
      csOperatorPhone: null,
      csOperatorWorkingHours: null,
    };
  },

  computed: {
    isShownPaymentConfigNotice() {
      return this.userIsAdmin && this.checkUsingCompanyAdmin && !this.isAdminInCompanyPays;
    },

    adyenProviderVersion() {
      return this.v71BEPSD2Refactor ? 'Adyen/5.59.0' : DEFAULT_PAYMENT_PROVIDER;
    },
  },

  created() {
    this.ACTIONS = ACTIONS;
    this.RESPONSE_STATUS = RESPONSE_STATUS;
    this.emptyPaymentMethodImage = emptyPaymentMethods;
    this.successFeedbackText = this.$t('modal.add_payment_method.success.title');

    this.v71BEPSD2Refactor = this.$featureFlag.flags.csrev3501AddCard;
  },

  mounted() {
    const CSOperator = this.currentCSOperator;
    this.csOperatorWorkingHours = isNil(CSOperator?.customer_service_opening_hours) ? '' : CSOperator.customer_service_opening_hours;
    this.csOperatorPhone = isNil(CSOperator?.customer_service_phone) ? '' : CSOperator.customer_service_phone;

    this.trackSegment({ name: SEGMENT_EVENTS.MY_PAYMENT_METHODS });
  },

  methods: {
    onResponse(response, action) {
      this.onAddPaymentAction(response, action, this.$parent);
    },
    onPaymentMethodError(message, priority) {
      this.notifyError({
        text: message,
        priority,
      });
    },
    onPaymentMethodSuccess(message) {
      this.notifySuccess({
        text: message,
        textAction: this.$t('buttons.done'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@emobg/sass/colors/variables";

.PaymentConfigNotice {
  width: 565px;
  padding: 2rem;
  background-color: map-get($motion-grayscale, ground-lighter);

  p {
    font-weight: 100;
    color: map-get($motion-grayscale, ink-light);

    &.PaymentConfigNotice__title {
      margin-bottom: 1rem;
    }

    &.PaymentConfigNotice__center-v {
      height: 3rem;
    }
  }
}

</style>
